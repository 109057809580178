form {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 10px;
}

.h1 {
  font-weight: 100;
  color: black;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.h2 {
  text-align: left;
  font-weight: 40;
  font-size: 1.2em;
  color: black;
  padding-bottom: 10px;
}

.h3 {
  text-align: left;
  font-weight: 40;
  font-size: 1em;
  color: black;
  padding-bottom: 10px;
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 10em;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}


label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: black;
  font-size: 1em;
  font-weight: 200;
}

input[type="submit"] {
  background: #0052d6;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

input[type="submit"]:hover {
  background: #bf1650;
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}
