.App {
  text-align: center;
  background-color: #f3f6fb;
}

.App-header {
  background-color: #f3f6fb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}

.topper {
  position: absolute;
  top: 5vh;
  left: 0;
  right: 0;
  min-width: 100%;
  display: flex;
  align-items: center;
}

.topper h1 {
  margin: 0 auto;
  font-size: 48px;
  font-size: 15vw;
  text-align: left;
  line-height: 56px;
  line-height: 15vw;
  font-family: Spectral;
  overflow: visible;
}

@media only screen and (min-width: 800px) {
  .App-header h1 {
    font-size: 90px;
    line-height: 90px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .App-header h1 {
    font-size: 176px;
    line-height: 176px;
  }
}

.main-dog {
  height: 100vh;
}

.Map {
  font-family: 'Spectral';
  background-color: #c4d7e6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}

.Map h2 {
  font-size: 2.5rem;
}

.Map img {
  max-height: 100vh;
  max-width: 80%;
}

.Map a {
  font-family: 'Open Sans Condensed';
  border: 3px solid black;
  font-weight: 300;
  padding: 15px 25px;
  text-decoration: none;
  color: black;
  font-size: 2rem;
  transition: 0.5s;
}

@media only screen and (min-width: 900px) {
  .Map h2 {
    font-size: 3.5rem;
  }
  .Map a {
    font-size: 3.5rem;
  }
}

.Map a:hover {
  color: #c4d7e6;
  background-color: black;
  border-color: black;
}

.Map a:last-of-type {
  margin-top: 20px;
  padding: 0px;
  border: none;
}

.Map a:last-of-type:hover {
  color: black;
  background-color: #c4d7e6;
  border-color: #c4d7e6;
}

.Free {
  font-family: 'Spectral';
  background-color: #f3f6fb;
  max-width: 900px;
  min-height: 100vh;
  display: block;
  margin:auto;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}

.Free h2 {
  margin-left: 10px;
  font-size: 2rem;
  text-align: left;
}

.Free h2 span {
  font-size: 2rem;
  font-family: 'Open Sans Condensed'
}

.fear-free {
  width: 720px;
  max-width: 80%;
}

.psa {
  width: 500px;
  max-width: 80%;
  padding-bottom: 2em;
}

.centered {
  align-items: center;
}

.pad-james {
  padding: 40px 0px 20px 0px;
  margin: 0 auto;
}

.james {
  display: block;
  margin: auto;
  border-radius: 50%;
  width: 540px;
  max-width: 60%;
}

@media only screen and (min-width: 600px) {
  .Free h2 {
    font-size: 2.8rem;
  }
  .Free h2 span {
    font-size: 2.8rem;
  }
}

@media only screen and (min-width: 900px) {
  .Free h2 {
    font-size: 3.5rem;
  }
  .Free h2 span {
    font-size: 3.5rem;
  }
}

.Projects {
  background-color: #f3f6fb;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}

.Projects a {
  font-family: 'Open Sans Condensed';
  border: 3px solid black;
  font-weight: 300;
  padding: 15px 25px;
  margin: 10px 30px;
  text-decoration: none;
  color: black;
  font-size: 2rem;
  transition: 0.5s;
}

.LinkButton {
  font-family: 'Open Sans Condensed';
  border: 3px solid black;
  font-weight: 300;
  padding: 15px 25px;
  margin: 10px 30px;
  text-decoration: none;
  color: black;
  font-size: 2rem;
  transition: 0.5s;
}

@media only screen and (min-width: 900px) {
  .Projects h2 {
    font-size: 3.5rem;
  }
  .Projects a {
    font-size: 3.5rem;
  }
  .LinkButton {
    font-size: 3.5rem;
  }
}

.Projects a:hover {
  color: #f3f6fb;
  background-color: black;
  border-color: black;
}

.LinkButton:hover {
  color: #f3f6fb;
  background-color: black;
  border-color: black;
}

.small-scottie {
  width: 65vw;
  max-width: 282px;
  max-height: 208px;
}

.small-dog {
  width: 50vw;
  max-width: 151px;
  max-height: 182px;
  padding-bottom: 80px;
}
